* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
  }
  
  
  .App {
    position: relative;
    height: 100vh;
    overflow: hidden;
    width: 100vw;
  }
  
.myInput:focus{
    border: none !important;
    border: 0px;
    outline: none;

}
.parameterBar{
    width: 530px;
    overflow: auto;
    height: calc(100vh - 58px);
    scrollbar-width:none;
}

.App::-webkit-scrollbar{  
  width: 0px;
  height: 5px;
  border-radius: 30px;
}
 
.App::-webkit-scrollbar-thumb {
  border-radius: 30px;
}

.RecentScroll::-webkit-scrollbar{  
  width: 0px;
  height: 10px;
  border-radius: 30px;
}

 
.RecentScroll::-webkit-scrollbar-thumb {
  border-radius: 30px;
  height: 10px;
}

.RecentScroll::-webkit-scrollbar:hover {
  width: 0px;
  transition: width 0.5s ease-in;
  height: 10px; 
}
.RecentScroll:hover::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.15);
  border-radius: 30px;
  height: 10px;
}

.parameterBar::-webkit-scrollbar{
  width: 0px;
  height: 5px;
  border-radius: 30px;
  scrollbar-width: 0px;
}

.parameterBar::-moz-scrollbar{
  width: 0px;
  height: 5px;
  border-radius: 30px;
}
 
/* .mainView::-webkit-scrollbar-thumb {
  border-radius: 30px;
}

.mainView::-webkit-scrollbar{  
  width: 10px;
  height: 5px;
  border-radius: 30px; 
  scrollbar-width:none;
} */

 
.parameterBar::-webkit-scrollbar-thumb {
  border-radius: 30px;
}

*::-webkit-scrollbar:hover {
  width: 0px;
  transition: width 0.5s ease-in; 
}
*:hover::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.15);
  border-radius: 30px;
}
.fieldSearch{
  width: 500px;
}
.myLogo{
  height: 45px;
}
.HeadIcon{
  font-size: 24px;
}
.bgImage{
  background-image: url(./media/bg.png);
} 

.Dashboard{
  height: calc(100vh - 59px);
  overflow: auto;
}

.ZMbutton{
  right: 550px;
}
.backBtnMobile{
  bottom:80px;
}
.backBtnMobileEdit{
  bottom:80px;
}
.RecentScroll{
  overflow: auto;
  scrollbar-width:thin;
}
.mobileHeight{
 width: 15vw;
 height: 15vw;
}
.dropdownBody{
  padding: 10px;
  border: 1px solid grey;
  width: 700px;
  max-height: 500px;
  overflow: auto;
  height: 100%;
}

.searchIn{
  width:90%;
  margin-left: 20px;
}
.searchIn:focus{
  outline: none;
}
.nodataimage{
  height: 50vh !important;
}
.css-1u9des2-indicatorSeparator{
  background-color: transparent !important;
}
.css-t3ipsp-control:hover{
  border-color: #1f2937 !important;
  border: 2px solid #1f2937 !important;
  border-radius: 0.375rem !important;
  
}
.css-t3ipsp-control:focus-visible{
  border-color: #1f2937 !important;
  border: 2px solid #1f2937 !important;
  border-radius: 0.375rem !important;
  
}
.css-t3ipsp-control:hover{
  border-color: #1f2937 !important;
  border: 2px solid #1f2937 !important;
  border-radius: 0.375rem !important;
  min-height: 32px !important;
}
.css-t3ipsp-control{
  box-shadow: none !important;
  border-color: #1f2937 !important;
  border: 2px solid #1f2937 !important;  
}
.css-13cymwt-control{
  border-radius: 0.375rem !important;
  border-color:#e5e7eb !important;
  min-height: 32px !important;
}
.css-1xc3v61-indicatorContainer{
  color: #1f2937 !important;
  padding:5px !important;
}
.css-15lsz6c-indicatorContainer{
  color: #1f2937 !important;
  padding:5px !important;
}

@media screen and (min-width: 1024px) and (max-width: 1278px){
  .mobileHeight{
    width: 22vw;
    height: 22vw;
   }
}






@media screen and (min-width: 767px) and (max-width: 1023px){
  .App {
    position: relative;
    height: 100vh;
    overflow: hidden;
    width: 100vw;
  }
  .parameterBar{
    width: 360px;
    height: calc(100vh - 58px);
  }
  .ZMbutton{
    right: 370px;
  }
  .logoSpace{
    margin-bottom: 2rem;
  }
  .LoginForm{
    margin-top: 2rem;
  }
  .Login{
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .mobileHeight{
    width: 30vw;
    height: 30vw;
   }
}








@media screen and (max-width: 767px){
  .mobileHeight{
    width: 42vw;
    height: 42vw;
   }
  .fieldSearch, .UserName{
    display: none;
  }
  .parameterBar{
    width: auto;
    margin-bottom: 70px;
  }
  .ZMbutton{
    right:20px;
  }
  .backBtnMobile{
   bottom: calc(100vh - 44vh);
  }
  .backBtnMobileEdit{
   bottom: calc(100vh - 44vh);
  }
  .mobileHeight{
    height: 170px;
  }
  .LoginLogo{
    height: 3rem;
  }
  .LoginHeadline{
    margin-bottom: 2rem;
  }
  .logoSpace{
    margin-bottom: 2rem;
  }
  .LoginForm{
    margin-top: 2rem;
  }
  .Login{
    padding-top: 10px;
    padding-bottom: 10px;
  }
}