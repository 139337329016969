/* src/index.css */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@font-face {
  font-family: AmazonEmber;
  src: url("../public/fonts/amazon-ember-cufonfonts/Amazon\ Ember.ttf");
}
@font-face {
  font-family: AmazonEmberBold;
  src: url("../public/fonts/amazon-ember-cufonfonts//Amazon\ Ember\ Bold.ttf");
} 
@font-face {
  font-family: AmazonEmberItalic;
  src: url("../public/fonts/amazon-ember-cufonfonts/Amazon\ Ember\ Italic.ttf");
} 

 body {
font-family: AmazonEmber;
}

/* Add your custom styles here */

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
